exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-charity-tsx": () => import("./../../../src/pages/charity.tsx" /* webpackChunkName: "component---src-pages-charity-tsx" */),
  "component---src-pages-child-donations-tsx": () => import("./../../../src/pages/child-donations.tsx" /* webpackChunkName: "component---src-pages-child-donations-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-subscription-1-tsx": () => import("./../../../src/pages/subscription1.tsx" /* webpackChunkName: "component---src-pages-subscription-1-tsx" */),
  "component---src-pages-subscription-2-tsx": () => import("./../../../src/pages/subscription2.tsx" /* webpackChunkName: "component---src-pages-subscription-2-tsx" */),
  "component---src-pages-subscription-3-tsx": () => import("./../../../src/pages/subscription3.tsx" /* webpackChunkName: "component---src-pages-subscription-3-tsx" */),
  "component---src-pages-subscription-4-tsx": () => import("./../../../src/pages/subscription4.tsx" /* webpackChunkName: "component---src-pages-subscription-4-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */)
}

